import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../common/Loader";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBuilderByIdThunk } from '../../../store/slices/builderSlice';
import { fetchPropertyByIdThunk } from '../../../store/slices/propertySlice';
import formatPropertyId from "../../common/PropertyIdConverter";
import {
    updateFormData,
    submitBuyerDataThunk,
    setTermsAccepted
} from '../../../store/slices/reservationFormSlice';
import { INITIAL_USER_INFO, TOTAL_STEPS } from './constants';
import useFormValidation from './hooks/useFormValidation';
import { AgentStep, NameStep, EmailStep, PhoneStep, AddressStep } from './steps';
import NextButton from './components/NextButton';
import { fetchUserReservationThunk } from '../../../store/slices/reservationSlice';
import { createSelector } from '@reduxjs/toolkit';

// Base selectors
const selectReservationState = state => state.reservation;
const selectReservationFormState = state => state.reservationForm;
const selectPropertyState = state => state.property;

// Memoized selectors
const selectReservations = createSelector(
  [selectReservationState],
  (reservationState) => (reservationState?.reservations ?? [])
);

const selectTermsAccepted = createSelector(
  [selectReservationFormState],
  (reservationFormState) => Boolean(reservationFormState?.termsAccepted)
);

const selectProperty = createSelector(
  [selectPropertyState],
  (propertyState) => propertyState?.property ?? null
);

const ReserveUserForm = () => {
  const { propertySlug } = useParams();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth0();
  const [currentStep, setCurrentStep] = useState(1);
  const [userInfo, setUserInfo] = useState({
    ...INITIAL_USER_INFO,
    email: user?.email,
    userId: user?.sub,
  });

  // Update selectors to handle undefined state
  const { builder, loading: builderLoading } = useSelector((state) => state.builder || {});
  const builderConfig = builder?.builder?.builderConfig;
  const reservations = useSelector(selectReservations);
  const termsAccepted = useSelector(selectTermsAccepted);
  const property = useSelector(selectProperty);

  const { errors, setErrors } = useFormValidation(termsAccepted);

  useEffect(() => {
    const checkReservation = async () => {
      if (user) {
        try {
          const isPropertyReservedByUser = Array.isArray(reservations) && reservations.some(
            reservation => reservation?.propertyId === property?.propertyid
          );
          console.log( reservations, property?.propertyid, isPropertyReservedByUser);
          if (isPropertyReservedByUser) {
            navigate('/dashboard');
          } else if (termsAccepted && !isPropertyReservedByUser && userInfo.address?.trim()) {
            navigate(`/${propertySlug}/reserve/payment-method`);
          }
        } catch (error) {
          console.error('Error checking reservation:', error);
        }
      }
    };

    checkReservation();
  }, [user, dispatch, navigate, propertySlug, termsAccepted, property, reservations]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const propertyId = formatPropertyId(propertySlug);
        
        // Fetch property data first
        const propertyResult = await dispatch(fetchPropertyByIdThunk(propertyId)).unwrap();
        
        // Parallel fetch of builder and user reservation data
        await Promise.all([
          // Only fetch builder if we have a builderId
          propertyResult?.builderid && 
            dispatch(fetchBuilderByIdThunk(propertyResult.builderid)).unwrap(),
          
          // Only fetch reservation data if user is authenticated
          user?.sub && 
            dispatch(fetchUserReservationThunk({ 
              userId: user.sub, 
              token: "homesyToken" 
            })).unwrap()
        ].filter(Boolean)); // Remove any falsy values from promises array

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, propertySlug, user, reservations]);

  const handleNext = async (e) => {
    if (e) {
      e.preventDefault(); // Prevent form submission
    }

    // Validate current step
    let isValid = true;
    let newErrors = {};
    
    // Special validation for agent step
    if (step === 1 && !userInfo.agentName?.trim()) {
      newErrors.agentName = 'Please enter an agent name or click "Skip agent" if you found this house on your own';
      isValid = false;
    }

    // Validate based on step
    switch (step) {
      case 2: // Name Step
        if (!userInfo.firstName?.trim() || !userInfo.lastName?.trim()) {
          newErrors = {
            firstName: !userInfo.firstName?.trim() ? 'First name is required' : '',
            lastName: !userInfo.lastName?.trim() ? 'Last name is required' : ''
          };
          isValid = false;
        }
        break;

      case 3: // Email Step
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!userInfo.email?.trim() || !emailRegex.test(userInfo.email)) {
          newErrors.email = 'Please enter a valid email address';
          isValid = false;
        }
        break;

      case 4: // Phone Step
        const phoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
        if (!userInfo.phone || !phoneRegex.test(userInfo.phone)) {
          newErrors.phone = 'Please enter a valid 10-digit phone number';
          isValid = false;
        }
        break;

      case 5: // Address Step
        if (!userInfo.address?.trim()) {
          newErrors.address = 'Please enter your address';
          isValid = false;
        }
        if (!termsAccepted) {
          newErrors.terms = 'You must accept the Terms and Conditions to continue';
          isValid = false;
        }
        break;

      default:
        break;
    }

    // Set all errors at once
    if (!isValid) {
      setErrors(prev => ({
        ...prev,
        ...newErrors
      }));
      return;
    }

    // If validation passes, proceed to next step
    setStep(step + 1);
    if (currentStep < TOTAL_STEPS) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (step === 1) {
      navigate(`/${propertySlug}/reserve`);
    } else if (step > 1) {
      setStep(step - 1);
      setCurrentStep(currentStep - 1);
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Validate all required fields
    const validationErrors = {};
    
    // Only validate these fields if agentName is not provided
    if (!userInfo.firstName?.trim()) {
      validationErrors.firstName = 'First name is required';
    }
    if (!userInfo.lastName?.trim()) {
      validationErrors.lastName = 'Last name is required';
    }
    if (!userInfo.email?.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userInfo.email)) {
      validationErrors.email = 'Please enter a valid email address';
    }
    if (!userInfo.phone?.trim()) {
      validationErrors.phone = 'Phone number is required';
    } else if (!/^\(\d{3}\)\s\d{3}-\d{4}$/.test(userInfo.phone)) {
      validationErrors.phone = 'Please enter a valid phone number';
    }
    
    // Address validation
    if (!userInfo.address?.trim()) {
      validationErrors.address = 'Address is required';
    }
    if (!userInfo.city?.trim()) {
      validationErrors.city = 'City is required';
    }
    if (!userInfo.state?.trim()) {
      validationErrors.state = 'State is required';
    }
    if (!userInfo.pincode?.trim()) {
      validationErrors.pincode = 'Postal code is required';
    }
    if (!termsAccepted) {
      validationErrors.terms = 'You must accept the Terms and Conditions';
    }

    setErrors(validationErrors);
    
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      setIsLoading(true);
      
      let newaddress = userInfo.address.split(',')[0];
      
      const userData = {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        phoneNumber: userInfo.phone,
        addressLine1: newaddress,
        city: userInfo.city,
        state: userInfo.state,
        postalCode: userInfo.pincode,
        country: userInfo.country,
        addressLine2: userInfo.address2,
        userid: userInfo.userId,
        propertyId: property?.property?.propertyid,
        builderId: builder?.builder?.builderid,
      };

      const resultAction = await dispatch(submitBuyerDataThunk({ userData })).unwrap();
      
      if (resultAction) {
        let userInfoData = userInfo;
        userInfoData.address = newaddress;
        localStorage.setItem("userInfo", JSON.stringify(userInfoData));
        navigate(`/${propertySlug}/reserve/payment-method`);
      }
    } catch (error) {
      console.error('Submission error:', error);
      
      if (error.code === 'ERR_NETWORK') {
        setErrors(prev => ({
          ...prev,
          submit: 'Unable to connect to server. Please check your internet connection.'
        }));
      } else {
        setErrors(prev => ({
          ...prev,
          submit: error.message || 'An error occurred while saving your information. Please try again.'
        }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTermsChange = (e) => {
    const isChecked = e.target.checked;
    dispatch(setTermsAccepted(isChecked));

    // Clear any terms-related errors
    setErrors((prevErrors) => {
      const { terms, ...restErrors } = prevErrors;
      return restErrors;
    });
  };

  // Add error handling for Google Maps
  useEffect(() => {
    if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
      console.error('Google Maps API key is missing in environment variables');
    }
  }, []);

  const skipHandler = () => {
    setStep(2);
    setCurrentStep(2);
  };

  // Update the builder logo rendering
  // const builderLogo = builder?.builder?.builderConfig?.logo; // this will be used when we can move the brand image from backend
  const propertyImage = property?.image_urls?.[0];
  const propertyLine = property?.property?.property_line;
  const propertyAddress = property?.property?.property_address;

  // Update the button styles to use builderConfig
  const buttonStyle = {
    backgroundColor: builderConfig?.color || '#default-color',
  };

  const handleFieldChange = (field, value) => {
    if (field === 'errors') {
      setErrors(value);  // Handle errors separately
    } else {
      setUserInfo(prev => ({ ...prev, [field]: value }));
      dispatch(updateFormData({ [field]: value }));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleNext();
    }
  };

  const renderCurrentStep = () => {
    const commonProps = {
      userInfo,
      onChange: handleFieldChange,
      errors,
      onNext: handleNext,
      onKeyDown: handleKeyDown
    };

    switch (step) {
      case 1:
        return (
          <AgentStep 
            {...commonProps}
            skipHandler={skipHandler}
          />
        );
      case 2:
        return (
          <NameStep
            {...commonProps}
          />
        );
      case 3:
        return (
          <EmailStep
            {...commonProps}
          />
        );
      case 4:
        return (
          <PhoneStep
            {...commonProps}
          />
        );
      case 5:
        return (
          <AddressStep
            {...commonProps}
            handleTermsChange={handleTermsChange}
            termsAccepted={termsAccepted}
          />
        );
      default:
        return null;
    }
  };

  return isLoading || builderLoading ? (
    <Loader />
  ) : (
    <div className="min-h-screen flex flex-col items-center justify-center py-8">
      {/* Logo container - shown first on mobile/tablet */}
      <div className="lg:hidden w-full flex flex-col items-center text-center">
        <img
          src="/assets/images/traton_logo.png"
          alt="builder logo"
          className="w-[100px] md:w-[140px] lg:w-[175px] h-auto"
        />
        <p className="font-circular text-[13px] md:text-[15px] lg:text-[17px] font-[450] leading-[18px] md:leading-[20px] lg:leading-[20.24px] text-center text-customGray mt-2">
          Powered by <a href="https://yourhomesy.com" target="_blank" rel="noopener noreferrer"><span className="text-[#D66C42]">Homesy.</span></a>
        </p>
      </div>

      <div className="mx-auto flex flex-col lg:flex-row items-center justify-center w-full px-8">
        {/* Left Side */}
        <div className="w-full lg:w-1/2 flex flex-col items-center mb-8 lg:mb-0">
          <div className="mt-[20px] flex flex-col items-center justify-center md:w-full lg:items-start max-w-[598px]">
            <button
              type="button"
              onClick={handlePrevious}
              className="py-[15px] md:py-[18px] lg:py-[20px] text-gray-700 text-[13px] md:text-[15px] lg:text-[17px] font-semibold flex items-center justify-center mb-4"
            >
              <img
                src="../../../assets/images/back-arrow-icon.svg"
                alt="back-icon"
                className="w-[16px] md:w-[18px] lg:w-[20px] mr-2"
              />{" "}
              Previous step
            </button>
            <p className="font-circular text-customGray text-base md:text-lg lg:text-xl font-semibold leading-[24px] md:leading-[28px] lg:leading-[34px] text-center lg:text-left mb-4">
              {"Your Home"}
            </p>
            <div className="w-3/5 lg:w-full">
              <img
                src={propertyImage || "https://res.cloudinary.com/dsty70mlq/image/upload/v1725718413/Homesy/bxgmoynrthytia7dsgyx.png"}
                alt="property"
                className="w-full aspect-square rounded-2xl md:rounded-2xl lg:rounded-3xl object-cover"
              />
            </div>
            <div className="flex flex-col items-center lg:items-start mt-4">
              <div className="text-center lg:text-left">
                <p className="font-circular text-customGray text-[16px] md:text-[18px] lg:text-[20px] font-semibold leading-[22px] md:leading-[24px] lg:leading-[26px]">
                  {propertyLine || "305 Bennett Way"}
                </p>
                <p className="font-circular text-customGray text-[14px] md:text-[15px] lg:text-[17px]">
                  {propertyAddress || "Willow Ridge, Georgia 30132"}
                </p>
                <a 
                  className="font-circular text-[#349cf4] mt-2 inline-block cursor-pointer text-[13px] md:text-[14px] lg:text-[15px]"
                  href={property?.property?.property_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See details
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="w-full lg:w-1/2 flex flex-col items-center">
          {/* Logo container - shown only on desktop */}
          <div className="hidden lg:flex flex-col items-center text-center mb-8">
            <img
              src="/assets/images/traton_logo.png"
              alt="builder logo"
              className="w-[100px] md:w-[140px] lg:w-[175px] h-auto"
            />
            <p className="font-circular text-[13px] md:text-[15px] lg:text-[17px] font-[450] leading-[18px] md:leading-[20px] lg:leading-[20.24px] text-center text-customGray">
              Powered by <a href="https://yourhomesy.com" target="_blank" rel="noopener noreferrer"><span className="text-[#D66C42]">Homesy.</span></a>
            </p>
          </div>

          {/* Form Section */}
          <form
            className="flex flex-col input-placeholder items-center w-2/3 px-4 lg:px-8"
            onSubmit={(e) => e.preventDefault()}
            onKeyDown={handleKeyDown}
          >
            {renderCurrentStep()}

            <div className="flex w-full pt-[60px] justify-end">              
              <div className="text-right">
                 <NextButton
                  step={step}
                  totalSteps={TOTAL_STEPS}
                  onNext={handleNext}
                  onSubmit={handleSubmit}
                  buttonStyle={buttonStyle}
                    isLoading={isLoading}
                  />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReserveUserForm;