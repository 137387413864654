/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../../common/Loader";
import { fetchBuilderByIdThunk } from '../../../store/slices/builderSlice';
import { fetchPropertyByIdThunk } from '../../../store/slices/propertySlice';
import formatPropertyId from "../../common/PropertyIdConverter";
import { createOrVerifyUserThunk } from '../../../store/slices/userSlice';

function ReserveGettingStarted() {
  const { propertySlug } = useParams();
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading: userLoading } = useSelector((state) => state.user);

  // Get data from Redux store
  const { builder, loading: builderLoading } = useSelector((state) => state.builder);
  const builderConfig = builder?.builder.builderConfig;
  const { property, loading: propertyLoading } = useSelector((state) => state.property);

  useEffect(() => {
    const fetchData = async () => {
      try {
          const propertyId = formatPropertyId(propertySlug);
          const promises = [
          dispatch(fetchPropertyByIdThunk(propertyId)).unwrap()
            .then(propertyResult => {
              if (propertyResult?.builderid) {
                return dispatch(fetchBuilderByIdThunk(propertyResult.builderid));
              }
            })
        ];
        
        await Promise.all(promises);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch, propertySlug]);

  const handleGetStarted = async () => {
    if (!isAuthenticated) {
      const returnUrl = `/${propertySlug}/reserve/user-info`;
      
      // Store the dynamic segment (propertySlug) and return path separately
      sessionStorage.setItem('propertySlug', propertySlug);
      sessionStorage.setItem('returnPath', '/reserve/user-info');
      
      try {
        await loginWithRedirect({
          appState: { 
            returnTo: returnUrl,
            propertySlug: propertySlug 
          },
          authorizationParams: {
            redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
            response_type: 'code',
            prompt: 'login'
          }
        });
      } catch (error) {
        console.error('Login redirect error:', error);
      }
    } else {
      try {
        const token = "homesyToken";
        const userData = {
          userid: user.sub,
          email: user.email,
          firstName: user.given_name || '',
          lastName: user.family_name || ''
        };

        const resultAction = await dispatch(createOrVerifyUserThunk({ userData, token }));
        
        if (createOrVerifyUserThunk.fulfilled.match(resultAction)) {
          navigate(`/${propertySlug}/reserve/user-info`);
        } else {
          console.error('Failed to create/verify user:', resultAction.error);
        }
      } catch (error) {
        console.error('Error in handleGetStarted:', error);
      }
    }
  };

  if (builderLoading || propertyLoading || userLoading) {
    return <Loader />;
  }

  // Create button style object
  const buttonStyle = {
    backgroundColor: builderConfig?.color || '#default-color',
  };
    return (
    <div className="min-h-screen flex flex-col items-center justify-center py-8">
      {/* Logo container - shown first on mobile/tablet */}
      <div className="lg:hidden w-full flex flex-col items-center text-center">
        <img
          src="/assets/images/traton_logo.png"
          alt="homesylogo"
          className="w-[100px] md:w-[150px] lg:w-[175px] h-auto"
        />
        <p className="font-circular text-[15px] md:text-[17px] font-[450] leading-[20.24px] text-center text-customGray mt-2">
          Powered by <span className="text-[#D66C42]">Homesy</span>
        </p>
      </div>

      <div className="mx-auto flex flex-col lg:flex-row items-center justify-center w-full px-8">
        {/* Left Side */}
        <div className="w-full lg:w-1/2 flex flex-col items-center mb-8 lg:mb-0">
          <div className="mt-[20px] flex flex-col items-center justify-center md:w-full lg:items-start max-w-[598px]">
            <p className="font-circular text-customGray text-base md:text-lg lg:text-xl font-semibold leading-[24px] md:leading-[28px] lg:leading-[34px] text-center lg:text-left mb-4">
              {"Your Home"}
            </p>
            <div className="w-3/5 lg:w-full">
              <img
                src={
                  property?.image_urls?.length > 0
                    ? property?.image_urls[0]
                    : "/assets/images/placeholder_house.jpg"
                }
                alt={property?.propertyid}
                className="w-full aspect-square rounded-3xl object-cover"
              />
            </div>
            <div className="flex flex-col items-center lg:items-start mt-4">
              <div className="text-center lg:text-left">
                <p className="font-circular text-customGray text-[20px] font-semibold leading-[26px]">
                  {property?.property_line || "305 Bennett Way"}
                </p>
                <p className="font-circular text-customGray text-[17px]">
                  {property?.property_address || "Willow Ridge, Georgia 30132"}
                </p>
                <a
                  className="font-circular text-[#349cf4] mt-2 inline-block cursor-pointer text-[15px]"
                  href={property?.property_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See details
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="w-full lg:w-1/2 flex flex-col items-center h-full">
          {/* Logo container - shown only on desktop */}
          <div className="hidden lg:flex flex-col items-center text-center mb-8">
            <img
              src="/assets/images/traton_logo.png"
              alt="homesylogo"
              className="w-[175px] h-auto"
            />
            <p className="font-circular text-[15px] md:text-[17px] font-[450] leading-[20.24px] text-center text-customGray mt-2">
             Powered by <a href="https://yourhomesy.com" target="_blank" rel="noopener noreferrer"><span className="text-[#D66C42]">Homesy.</span></a>
            </p>
          </div>
          
          <div className="flex flex-col items-center justify-center text-center px-4 lg:px-8 w-full h-full">
           <div className="flex flex-col items-center justify-center text-center max-w-[600px]">
            <h1 className="font-circular text-[#0E2132] text-2xl md:text-3xl lg:text-5xl font-[450] leading-[40px] md:leading-[56px] lg:leading-[73px] mb-[10px] lg:mb-[20px]">
                Let's reserve your home.
              </h1>
              <p className="font-circular font-[450] text-basemd:text-lg lg:text-xl leading-[34px] text-center underline-offset-auto decoration-skip-ink-none mb-[40px]">
                We'll hold it for 48 hours while you schedule your tour, or if you need more time to think it over before purchasing.
              </p>
           </div>
            <button
              style={buttonStyle}
              className={`text-white start-btn px-8 py-4 rounded-full font-circular font-light text-[18px] hover:opacity-90 transition-opacity`}
              onClick={handleGetStarted}
            >
              Get started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReserveGettingStarted;