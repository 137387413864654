export const GOOGLE_MAPS_LIBRARIES = ["places"];

export const INITIAL_USER_INFO = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  pincode: "",
  country: "USA",
  state: "",
  address2: "",
  userId: "",
  agentName: "",
};

export const TOTAL_STEPS = 5; 