import React from 'react';

const CancelReservation = () => {
  const handleCancelClick = () => {
    alert('Please contact support@yourhomesy.com to start the cancellation process, or wait until the end of your reservation period for a full refund.');
  };

  return (
    <div className="py-2">
      <button
        onClick={handleCancelClick}
        className="text-xs text-red-600 hover:text-red-800 font-medium"
      >
        Cancel Reservation
      </button>
    </div>
  );
};

export default CancelReservation;

