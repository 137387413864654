import React from 'react';

const ProgressTracker = () => {
  const steps = [
    { name: 'Reserve', status: 'completed' },
    { name: 'Schedule', status: 'current' },
    { name: 'Offer', status: 'upcoming' },
    { name: 'Due Diligence', status: 'upcoming' },
    { name: 'Close', status: 'upcoming' }
  ];

  return (
    <div className="w-full flex flex-row justify-between">
      <div className="flex flex-row justify-between w-2/3 relative">
        <div className="absolute top-[.75rem] h-[2px] bg-[#D9D9D9] w-[calc(100%-1rem)] left-[1rem] rounded-full" />
        {steps.map((step, index) => (
          <div key={step.name} className="flex flex-col items-center gap-2 mb-4 last:mb-0 relative">
            {/* Vertical timeline line that spans full height */}
            <div className="relative flex items-center justify-center z-10">
              <div 
                className={`w-6 h-6 rounded-full flex items-center justify-center border-2
                  ${step.status === 'current' ? 'bg-white border-black' : 
                    step.status === 'completed' ? 'bg-green-500 border-black' :
                    'bg-white border-[#D9D9D9]'}`}
              >
                {step.status === 'completed' && (
                  <svg className="w-2 h-2 text-white" viewBox="0 0 12 12" fill="none">
                    <path d="M10 3L4.5 8.5L2 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                )}
              </div>
            </div>
            <div className="mt-2">
              <h3 className={`text-xs font-medium
                ${step.status === 'current' ? 'text-black' : 
                  step.status === 'completed' ? 'text-gray-900' : 
                  'text-[#929292]'}`}
              >
                {step.name}
              </h3>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="flex items-center mb-6 justify-between">
        <button className="px-4 py-2 text-xs text-gray-600 hover:bg-gray-50 flex items-center gap-2 border border-black rounded-md">
          Documents
        </button>
      </div> */}
    </div>
  );
};

export default ProgressTracker;
