import React, { useState } from 'react';

const TermAndCondition = () => {
    const [activeTab, setActiveTab] = useState('termsAndConditions');

    const renderContent = () => {
        switch (activeTab) {
            case 'termsAndConditions':
                return (
                    <>
                        <h2 className="text-2xl font-semibold mb-4">Terms and Conditions</h2>
                        <p className="text-gray-600 mb-4">Last Updated: November 18, 2024</p>
                        
                        <h3 className="text-xl font-semibold mb-2">Acceptance of Terms</h3>
                        <p className="text-gray-700 mb-4">
                            By accessing or using this Website, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, please do not use this Website.
                        </p>

                        <h3 className="text-xl font-semibold mb-2">Services Provided</h3>
                        <p className="text-gray-700 mb-4">
                            YourHomesy.com offers a platform for homebuilders to list properties and for customers to purchase homes directly. We provide tools such as the "Reserve Now" feature to facilitate transactions between builders and buyers.
                        </p>

                        <h3 className="text-xl font-semibold mb-2">"Reserve Now" Feature</h3>
                        <p className="text-gray-700 mb-4">
                            - <strong>Reservation Fee:</strong> A non-refundable fee of $100 is required to reserve a property.
                            <br />
                            - <strong>Cancellation Policy:</strong> If you wish to cancel your reservation, you must do so within 48 hours from the time of reservation to be eligible for a refund.
                        </p>

                        <h3 className="text-xl font-semibold mb-2">Contact Information</h3>
                        <p className="text-gray-700 mb-4">
                            For any questions or concerns regarding these Terms, please contact us at <strong>support@yourhomesy.com</strong>.
                        </p>
                    </>
                );

            case 'privacyPolicy':
                return (
                    <>
                        <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
                        <p className="text-gray-600 mb-4">Last Updated: November 18, 2024</p>
                        
                        <h3 className="text-xl font-semibold mb-2">Information Collection</h3>
                        <p className="text-gray-700 mb-4">
                            We collect personal information that you provide to us directly, such as when you create an account, make a reservation, or contact us for support. This may include your name, email address, phone number, and payment information.
                        </p>

                        <h3 className="text-xl font-semibold mb-2">Data Security</h3>
                        <p className="text-gray-700 mb-4">
                            We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
                        </p>

                        <h3 className="text-xl font-semibold mb-2">Your Rights</h3>
                        <p className="text-gray-700 mb-4">
                            Depending on your jurisdiction, you may have the right to access, correct, or delete your personal information. To exercise these rights, please contact us at <strong>support@yourhomesy.com</strong>.
                        </p>
                    </>
                );

            case 'cookiePolicy':
                return (
                    <>
                        <h2 className="text-2xl font-semibold mb-4">Cookie Policy</h2>
                        <p className="text-gray-600 mb-4">Last Updated: November 18, 2024</p>

                        <h3 className="text-xl font-semibold mb-2">What Are Cookies?</h3>
                        <p className="text-gray-700 mb-4">
                            Cookies are small text files that are placed on your device when you visit our website. They help us provide you with a better experience by remembering your preferences, analyzing site traffic, and personalizing content.
                        </p>

                        <h3 className="text-xl font-semibold mb-2">Types of Cookies We Use</h3>
                        <p className="text-gray-700 mb-4">
                            <strong>Essential Cookies:</strong> Required for basic website functionality.
                            <br />
                            <strong>Analytics Cookies:</strong> Help us understand how visitors interact with our website.
                            <br />
                            <strong>Preference Cookies:</strong> Remember your settings and choices.
                            <br />
                            <strong>Marketing Cookies:</strong> Used to deliver relevant advertisements.
                        </p>

                        <h3 className="text-xl font-semibold mb-2">Managing Cookies</h3>
                        <p className="text-gray-700 mb-4">
                            You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed.
                        </p>
                    </>
                );

            default:
                return null;
        }
    };

    const tabs = [
        { id: 'termsAndConditions', label: 'Terms and Conditions' },
        { id: 'privacyPolicy', label: 'Privacy Policy' },
        { id: 'cookiePolicy', label: 'Cookie Policy' }
    ];

    return (
        <>
            <div className="w-full flex items-center justify-center px-8 py-4 bg-white shadow-md">
                <img
                    src="./assets/images/main_logo.png"
                    alt="logo"
                    className="w-24 h-auto"
                />
            </div>
            <div className="bg-gray-50 text-gray-800 min-h-screen py-10">
                <div className="w-[90%] max-w-7xl mx-auto">
                    <div className="flex flex-col md:flex-row gap-8">
                        {/* Sidebar */}
                        <aside className="w-full md:w-1/4 bg-white p-6 rounded-lg shadow-lg h-fit">
                            <nav>
                                <ul className="space-y-4">
                                    {tabs.map(tab => (
                                        <li key={tab.id}>
                                        <button 
                                                onClick={() => setActiveTab(tab.id)} 
                                                className={`w-full text-left font-semibold transition-colors
                                                    ${activeTab === tab.id 
                                                        ? 'text-blue-600' 
                                                        : 'text-gray-600 hover:text-blue-600'
                                                    }`}
                                            >
                                                {tab.label}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </aside>

                        {/* Main Content */}
                        <main className="w-full md:w-3/4">
                            <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
                                <h1 className="text-3xl font-bold mb-6">Terms & Policies</h1>
                                <div className="border-b border-gray-300 mb-6"></div>
                                {renderContent()}
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermAndCondition;
