import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  fetchUserReservations, 
  createReservation 
} from '../../api/reservationApi';

// Thunks
export const fetchUserReservationThunk = createAsyncThunk(
  'reservations/fetchUserReservation',
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await fetchUserReservations(userId, token);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createReservationThunk = createAsyncThunk(
  'reservations/createReservation',
  async (reservationData, { rejectWithValue }) => {
    try {
      const response = await createReservation(reservationData);
      console.log(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const reservationSlice = createSlice({
  name: 'reservations',
  initialState: {
    reservations: [],
    currentReservation: null,
    loading: false,
    error: null
  },
  reducers: {
    clearReservations: (state) => {
      state.reservations = [];
      state.currentReservation = null;
      state.error = null;
    },
    setCurrentReservation: (state, action) => {
      state.currentReservation = action.payload;
    },
    setReservationByPropertySlug: (state, action) => {
      const propertySlug = action.payload;
      const matchingReservation = state.reservations.find(
        reservation => 
          reservation?.property?.propertyid === propertySlug &&
          reservation.status === 'reserved' &&
          new Date(reservation.expirationDate) > new Date()
      );
      
      state.currentReservation = matchingReservation || null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch reservations
      .addCase(fetchUserReservationThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserReservationThunk.fulfilled, (state, action) => {
        state.loading = false;
        
        if (action.payload?.status === 'success' && 
            Array.isArray(action.payload.data) && 
            action.payload.data.length > 0) {
          
          state.reservations = action.payload.data;
          
          const activeReservation = action.payload.data.find(
            reservation => 
              reservation && 
              reservation.status === 'reserved' && 
              new Date(reservation.expirationDate) > new Date()
          );
          
          state.currentReservation = activeReservation || null;
        } else {
          state.reservations = [];
          state.currentReservation = null;
        }
      })
      .addCase(fetchUserReservationThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reservations = [];
        state.currentReservation = null;
      })
      // Create reservation
      .addCase(createReservationThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createReservationThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.currentReservation = action.payload;
        state.reservations.push(action.payload);
      })
      .addCase(createReservationThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { 
  clearReservations, 
  setCurrentReservation, 
  setReservationByPropertySlug 
} = reservationSlice.actions;
export default reservationSlice.reducer; 