/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import PaymentModal from './PaymentModal';
import ProgressBar from "../../ProgressBar";
import Loader from "../../../common/Loader";
import { createReservationThunk } from '../../../store/slices/reservationSlice';

const ReservePayment = () => {
  const navigate = useNavigate();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const dispatch = useDispatch();
  
  // Get data from Redux store
  const { builder, loading: builderLoading } = useSelector((state) => state.builder);
  const builderConfig = builder?.builder?.builderConfig;
  const { property, loading: propertyLoading } = useSelector((state) => state.property);
  const { userData } = useSelector((state) => state.user);
  const { propertySlug } = useParams();

  const handlePaymentSuccess = async (paymentResult) => {
    try {
      // Create reservation record
      const reservationData = {
        propertyId: property.propertyid,
        userId: userData.userid,
        paymentId: paymentResult.id,
      };

      // Store reservation data in Redux
      await dispatch(createReservationThunk(reservationData)).unwrap();

      // Navigate to complete page
      navigate(`/${propertySlug}/reserve/complete`);
    } catch (error) {
      console.error('Error creating reservation:', error);
      // Handle error appropriately
    }
  };

  const handlePrevious = () => {
    navigate(-1);
  };

  const handleReserveClick = () => {
    setShowPaymentModal(true);
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  // Create button style object
  const buttonStyle = {
    backgroundColor: builderConfig?.color || '#default-color',
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && showPaymentModal) {
        closePaymentModal();
      }
    };

    document.addEventListener('keydown', handleEscKey);

    // Cleanup listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [showPaymentModal]); // Only re-run effect if showPaymentModal changes

  return builderLoading || propertyLoading ? (
    <Loader />
  ) : (
    <div className="min-h-screen flex flex-col items-center justify-center py-8">
      {/* Logo container - shown first on mobile/tablet */}
      <div className="lg:hidden w-full flex flex-col items-center text-center mb-8">
        <img
          src="/assets/images/traton_logo.png"
          alt="builder logo"
          className="w-[140px] md:w-[175px] h-auto"
        />
        <p className="font-circular text-[15px] md:text-[17px] font-[450] leading-[20.24px] text-center text-customGray mt-2">
          Powered by Homesy.
        </p>
      </div>

      <div className="mx-auto flex flex-col lg:flex-row items-center justify-center w-full px-8">
        {/* Left Side */}
        <div className="w-full lg:w-1/2 flex flex-col items-center mb-8 lg:mb-0">
          <div className="mt-[20px] w-full max-w-[598px]">
            <button
              type="button"
              onClick={handlePrevious}
              className="py-[20px] text-gray-700 text-[15px] md:text-[17px] font-semibold flex items-center justify-center mb-4"
            >
              <img
                src="../../../assets/images/back-arrow-icon.svg"
                alt="back-icon"
              />{" "}
              Previous step
            </button>
            <div className="w-full">
              <img
                src={property?.property?.image_urls?.[0] || "https://res.cloudinary.com/dsty70mlq/image/upload/v1725718413/Homesy/bxgmoynrthytia7dsgyx.png"}
                alt="property"
                className="w-full aspect-square rounded-3xl object-cover"
              />
            </div>
            <div className="flex flex-col items-center lg:items-start mt-4">
              <div className="text-center lg:text-left">
                <p className="font-circular text-customGray text-[20px] font-semibold leading-[26px]">
                  {property?.property?.property_line || "305 Bennett Way"}
                </p>
                <p className="font-circular text-customGray text-[17px]">
                  {property?.property?.property_address || "Willow Ridge, Georgia 30132"}
                </p>
                <a 
                  className="font-circular text-[#349cf4] mt-2 inline-block cursor-pointer text-[15px]"
                  href={property?.property?.property_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See details
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="w-full lg:w-1/2 flex flex-col items-center">
          {/* Logo container - shown only on desktop */}
          <div className="hidden lg:flex flex-col items-center text-center mb-8">
            <img
              src="/assets/images/traton_logo.png"
              alt="builder logo"
              className="w-[175px] h-auto"
            />
            <p className="font-circular text-[17px] font-[450] leading-[20.24px] text-center text-customGray">
              Powered by Homesy.
            </p>
          </div>

          {/* Content Section */}
          <div className="flex flex-col items-center justify-center text-center px-4 lg:px-8 w-full">
            <div className="flex flex-col items-center justify-center text-center max-w-[600px]">
              <ProgressBar currentStep={6} totalSteps={6} />
              <h1 className="font-circular text-[#0E2132] text-5xl font-[450] leading-[73px] mb-[20px]">
                Perfect. Now let's get your home reserved.
              </h1>
              <p className="font-circular font-[450] text-xl leading-[34px] text-center underline-offset-auto decoration-skip-ink-none mb-[40px]">
                It's completely refundable during your 48 hours holding period while you schedule a viewing or continue to think it over.
              </p>
              <button
                type="button"
                onClick={handleReserveClick}
                style={buttonStyle}
                className="text-white start-btn px-8 py-4 rounded-full font-circular font-light text-[18px] hover:opacity-90 transition-opacity"
              >
                Reserve home for $100
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Payment Modal */}
      {showPaymentModal && (
        <PaymentModal
          builderConfig={builderConfig}
          closePaymentMethod={closePaymentModal}
          propertyData={property?.property}
          userInfo={userData}
          builderInfo={builder?.builder}
          onPaymentSuccess={handlePaymentSuccess}
        />
      )}
    </div>
  );
};

export default ReservePayment;
