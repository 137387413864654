import React from "react";
import { useSelector } from "react-redux";

function ReserveReserved() {
  // Get data from Redux store
  const { builder } = useSelector((state) => state.builder);
  const builderConfig = builder?.builder?.builderConfig;
  const { property } = useSelector((state) => state.property);

  // Create button style object
  const buttonStyle = {
    backgroundColor: builderConfig?.color || '#default-color',
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-8">
      {/* Logo container - shown first on mobile/tablet */}
      <div className="lg:hidden w-full flex flex-col items-center text-center">
        <img
          src="/assets/images/traton_logo.png"
          alt="homesylogo"
          className="w-[100px] md:w-[150px] lg:w-[175px] h-auto"
        />
        <p className="font-circular text-[15px] md:text-[17px] font-[450] leading-[20.24px] text-center text-customGray mt-2">
          Powered by <span className="text-[#D66C42]">Homesy</span>
        </p>
      </div>

      <div className="mx-auto flex flex-col lg:flex-row items-center justify-center w-full px-8">
        {/* Left Side */}
        <div className="w-full lg:w-1/2 flex flex-col items-center mb-8 lg:mb-0">
          <div className="mt-[20px] flex flex-col items-center justify-center md:w-full lg:items-start max-w-[598px]">
            <p className="font-circular text-customGray text-base md:text-lg lg:text-xl font-semibold leading-[24px] md:leading-[28px] lg:leading-[34px] text-center lg:text-left mb-4">
              {"Your Home"}
            </p>
            <div className="w-3/5 lg:w-full">
              <img
                src={
                  property?.image_urls?.length > 0
                    ? property?.image_urls[0]
                    : "/assets/images/placeholder_house.jpg"
                }
                alt={property?.propertyid}
                className="w-full aspect-square rounded-3xl object-cover"
              />
            </div>
            <div className="flex flex-col items-center lg:items-start mt-4">
              <div className="text-center lg:text-left">
                <p className="font-circular text-customGray text-[20px] font-semibold leading-[26px]">
                  {property?.property_line || "305 Bennett Way"}
                </p>
                <p className="font-circular text-customGray text-[17px]">
                  {property?.property_address || "Willow Ridge, Georgia 30132"}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="w-full lg:w-1/2 flex flex-col items-center h-full">
          {/* Logo container - shown only on desktop */}
          <div className="hidden lg:flex flex-col items-center text-center mb-8">
            <img
              src="/assets/images/traton_logo.png"
              alt="homesylogo"
              className="w-[175px] h-auto"
            />
            <p className="font-circular text-[15px] md:text-[17px] font-[450] leading-[20.24px] text-center text-customGray mt-2">
              Powered by <a href="https://yourhomesy.com" target="_blank" rel="noopener noreferrer"><span className="text-[#D66C42]">Homesy.</span></a>
            </p>
          </div>
          
          <div className="flex flex-col items-center justify-center text-center px-4 lg:px-8 w-full h-full">
            <div className="flex flex-col items-center justify-center text-center max-w-[600px]">
              <h1 className="font-circular text-[#0E2132] text-2xl md:text-3xl lg:text-5xl font-[450] leading-[40px] md:leading-[56px] lg:leading-[73px] mb-[10px] lg:mb-[20px]">
                Looks like the property is currently reserved by another interested buyer.
              </h1>
            </div>
            <a
              href={property?.property_url}
              style={buttonStyle}
              className={`text-white start-btn px-8 py-4 rounded-full font-circular font-light text-[18px] hover:opacity-90 transition-opacity`}
            >
              Navigate back to website
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReserveReserved;
