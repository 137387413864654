import React from 'react';
import ProgressBar from '../../../ProgressBar';

const EmailStep = ({ userInfo, onChange, errors, onNext, onKeyDown }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
    if (errors.email) {
      onChange('errors', {
        ...errors,
        email: ''
      });
    }
  };

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userInfo.email?.trim() || !emailRegex.test(userInfo.email)) {
      onChange('errors', {
        ...errors,
        email: 'Please enter a valid email address'
      });
      return false;
    }
    onNext();
    return true;
  };

  return (
    <>
      <ProgressBar currentStep={3} totalSteps={6} />
      <div className="mb-6">
        <h1 className="font-circular text-[#0E2132] text-2xl md:text-3xl lg:text-5xl font-[450] leading-[40px] md:leading-[56px] lg:leading-[73px] mb-[20px] text-center">
          Best email to send stuff?
        </h1>
        <p className="font-circular font-[450] text-base md:text-lg lg:text-xl leading-[24px] md:leading-[28px] lg:leading-[34px] text-center underline-offset-auto decoration-skip-ink-none mb-[40px]">
          This is where we'll send a copy of your contract
        </p>
      </div>
      <div className="w-full">
        <input
          type="text"
          placeholder="Email address"
          name="email"
          value={userInfo.email || ''}
          onChange={handleChange}
          className="w-full px-4 py-3 text-base md:text-lg text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
          onBlur={handleSubmit}
          onKeyDown={onKeyDown}
        />
        {errors.email && (
          <p className="text-red-500 text-sm text-left">
            {errors.email}
          </p>
        )}
      </div>
    </>
  );
};

export default EmailStep; 