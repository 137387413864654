import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReserveComplete from '../components/reserve-flow/ReserveComplete';
import ReservePayment from '../components/reserve-flow/ReservePayment';
import ReserveUserForm from '../components/reserve-flow/ReserveUserForm';
import ReserveGettingStarted from '../components/reserve-flow/ReserveGettingStarted';
import ProtectedRoute from '../components/auth/ProtectedRoute';
import ReserveReserved from '../components/reserve-flow/ReserveReserved';
const ReserveFlow = () => {
    const { propertySlug } = useParams();
    const selectedProperty = useSelector((state) => state.property.property);

    return (
        <Routes>
            <Route path="/" element={<ReserveGettingStarted />} />
            <Route element={<ProtectedRoute />}>
                <Route 
                    path="/user-info" 
                    element={
                        <ReserveUserForm 
                            property={selectedProperty}
                            slug={propertySlug}
                        />
                    } 
                />
                <Route 
                    path="/payment-method" 
                    element={
                        <ReservePayment
                            property={selectedProperty}
                            slug={propertySlug}
                        />
                    } 
                />
                <Route 
                    path="/complete" 
                    element={
                        <ReserveComplete
                            property={selectedProperty}
                            slug={propertySlug}
                        />
                    } 
                />
                <Route 
                    path="/reserved" 
                    element={
                        <ReserveReserved
                            property={selectedProperty}
                            slug={propertySlug}
                        />
                    } 
                />
            </Route>
        </Routes>
    );
};

export default ReserveFlow; 