import { useState, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_LIBRARIES } from '../constants';

export const useAddressInput = (onAddressChange) => {
  const [location, setLocation] = useState('');
  const autocompleteRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();

    if (place && place.address_components) {
      const addressComponents = place.address_components;
      const formattedAddress = place.formatted_address;

      const getComponent = (type) => {
        const component = addressComponents.find(comp => comp.types.includes(type));
        return component ? component.long_name : '';
      };

      const newFormData = {
        address: formattedAddress,
        city: getComponent('locality'),
        state: getComponent('administrative_area_level_1'),
        pincode: getComponent('postal_code'),
      };

      setLocation(formattedAddress);
      onAddressChange(newFormData);
    }
  };

  return {
    location,
    setLocation,
    autocompleteRef,
    isLoaded,
    loadError,
    handlePlaceChanged,
  };
};

export default useAddressInput; 