import { createSlice } from '@reduxjs/toolkit';

const flowSlice = createSlice({
  name: 'flow',
  initialState: {
    currentAction: null, // 'buy_now' or 'reserve_now'
  },
  reducers: {
    setAction(state, action) {
      state.currentAction = action.payload;
    },
    clearAction(state) {
      state.currentAction = null;
    },
  },
});

export const { setAction, clearAction } = flowSlice.actions;
export default flowSlice.reducer; 