function formatPropertyId(address) {
  /**
   * Formats a property address into a standardized ID format:
   * - Converts to lowercase
   * - Removes special characters
   * - Adds prefix 'prop'
   * - Adds state code
   * - Adds zip code
   * - Separates with hyphens
   */
  
  // Clean and split address
  const parts = address.toLowerCase().split('-');
  
  // Extract components
  const number = parts[0];
  const street = parts.slice(1, -3).join('-');  // Combine street parts
  const city = parts[parts.length - 3];
  const state = parts[parts.length - 2];
  const zipCode = parts[parts.length - 1];
  
  // State code mapping (can be expanded)
  const stateCodes = {
      'georgia': 'ga',
      // Add more states as needed
  };
  
  // Get state code or use first 2 letters of state name
  const stateCode = stateCodes[state] || state.slice(0, 2);
  
  // Format the ID
  const propertyId = `prop-${stateCode}-${zipCode}-${number}-${street}-${city}`;
  
  return propertyId;
}

export default formatPropertyId;