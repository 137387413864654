import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createOrVerifyUser = async (userData, token) => {

  try {
    const response = await axios.post(`${API_BASE_URL}/buyers`, userData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error in createOrVerifyUser:', error);
    throw error;
  }
}; 