import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createBuyer } from '../../api/buyerApi';

// Async thunk for submitting buyer data
export const submitBuyerDataThunk = createAsyncThunk(
    'reservationForm/submitBuyerData',
    async ({ userData, token }, { rejectWithValue }) => {
        try {
            const response = await createBuyer(userData, token);
            console.log('response', response.data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const reservationFormSlice = createSlice({
    name: 'reservationForm',
    initialState: {
        formData: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            pincode: "",
            country: "USA",
            state: "",
            address2: "",
            userId: null,
        },
        currentStep: 1,
        termsAccepted: false,
        loading: false,
        error: null,
        location: "",
    },
    reducers: {
        updateFormData: (state, action) => {
            state.formData = { ...state.formData, ...action.payload };
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setTermsAccepted: (state, action) => {
            state.termsAccepted = action.payload;
        },
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        clearForm: (state) => {
            state.formData = {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                address: "",
                city: "",
                pincode: "",
                country: "USA",
                state: "",
                address2: "",
                userId: null,
            };
            state.currentStep = 1;
            state.termsAccepted = false;
            state.location = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitBuyerDataThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitBuyerDataThunk.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(submitBuyerDataThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const {
    updateFormData,
    setCurrentStep,
    setTermsAccepted,
    setLocation,
    clearForm,
} = reservationFormSlice.actions;

export default reservationFormSlice.reducer; 