import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createOrVerifyUser } from '../../api/userApi';

export const createOrVerifyUserThunk = createAsyncThunk(
  'user/createOrVerify',
  async ({ userData, token }, { rejectWithValue }) => {
    try {
      const userDataWithId = {
        ...userData,
        userid: userData.userid || userData.sub
      };
      
      const response = await createOrVerifyUser(userDataWithId, token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create/verify user');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: null,
    loading: false,
    error: null
  },
  reducers: {
    clearUser: (state) => {
      state.userData = null;
      state.error = null;
    },
    setUserData: (state, action) => {
      state.userData = {
        ...state.userData,
        ...action.payload,
        userid: action.payload.userid || action.payload.sub
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrVerifyUserThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrVerifyUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = {
          ...action.payload.buyerInfo,
          userid: action.payload.buyerInfo.userid || action.payload.buyerInfo.sub
        };
      })
      .addCase(createOrVerifyUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { clearUser, setUserData } = userSlice.actions;
export default userSlice.reducer; 