const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch property data by property ID
export const fetchPropertyById = async (propertyId) => {
    const response = await fetch(`${API_BASE_URL}/properties/${propertyId}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    
    if (!response.ok) {
        throw new Error('Failed to fetch property');
    }
    
    const data = await response.json();
    return data.data.property;
};

// Add fetchAllProperties function
export const fetchAllProperties = async () => {
    const response = await fetch(`${API_BASE_URL}/properties`);
    const data = await response.json();
    if (!response.ok) {
        throw new Error('Failed to fetch properties');
    }
    return data.data;
};

// Add addProperty function
export const addProperty = async (propertyData) => {
    const response = await fetch(`${API_BASE_URL}/properties`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(propertyData),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to add property');
    }

    const data = await response.json();
        return data;
}