const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchBuilderAgents = async (builderId, token) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/builder-agents?builderId=${builderId}`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data.data;
  } catch (error) {
    console.error('Error fetching builder agents:', error);
    throw error;
  }
};

export const getBuilderAgent = async (agentId, token) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/builder-agents/${agentId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data.data;
  } catch (error) {
    console.error('Error fetching builder agent:', error);
    throw error;
  }
}; 