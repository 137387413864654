import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchUserReservationThunk } from '../../store/slices/reservationSlice';
import { fetchBuilderAgentsThunk } from '../../store/slices/builderAgentsSlice';
import { fetchPropertyByIdThunk } from '../../store/slices/propertySlice';
import TopNav from './topnav';
import HouseInfo from './summary/HouseInfo';
import ProgressTracker from './summary/ProgressTracker';
import Reservation from './summary/Reservation';
import CancelReservation from './summary/CancelReservation';
import Loader from '../../common/Loader';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { user, isLoading: authLoading, loginWithRedirect } = useAuth0();
  
  // Centralize all state selectors
  const { currentReservation, loading: reservationLoading } = useSelector((state) => state.reservations);
  const { builderAgents } = useSelector((state) => state.builderAgents);
  const { property, loading: propertyLoading } = useSelector((state) => state.property);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        console.log('No user, redirecting to login');
        try {
          await loginWithRedirect({
            authorizationParams: {
              redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
              response_type: 'code',
              prompt: 'login'
            }
          });
          return;
        } catch (error) {
          console.error('Login redirect error:', error);
        }
      }
      
      const token = "homesyToken";
      
      // Fetch user's reservation
      await dispatch(fetchUserReservationThunk({ 
        userId: user.sub,
        token 
      }));
    };

    fetchData();
  }, [dispatch, user, loginWithRedirect]);

  // Separate useEffect for property and builder agents
  useEffect(() => {
    const fetchPropertyData = async () => {
      if (currentReservation?.propertyId) {
        const token = "homesyToken";
        await dispatch(fetchPropertyByIdThunk(currentReservation.propertyId));
        
        if (currentReservation?.property?.builderid) {
          await dispatch(fetchBuilderAgentsThunk({ 
            builderId: currentReservation.property.builderid,
            token 
          }));
        }
      }
    };

    fetchPropertyData();
  }, [dispatch, currentReservation]);

  if (authLoading || reservationLoading || propertyLoading) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen">
      <TopNav user={user} />
      
      <main className="max-w-7xl mx-auto py-8 px-4">
        <div className="flex gap-8">
          {/* Left Column */}
          <div className="w-1/3">
            <div className="mb-8">
              <HouseInfo 
                selectedProperty={property}
                currentReservation={currentReservation}
              />
            </div>
          </div>

          {/* Right Column */}
          <div className="w-2/3">
            <div className="mb-8 p-6">
              <ProgressTracker 
                currentReservation={currentReservation}
                builderAgents={builderAgents}
              />
            </div>

            <div className="mb-4">
              <Reservation 
                currentReservation={currentReservation}
                builderAgents={builderAgents}
              />
            </div>

            {currentReservation && (
              <CancelReservation 
                reservationId={currentReservation.id}
                currentReservation={currentReservation}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
