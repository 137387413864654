import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useAuth0} from '@auth0/auth0-react';
import ReservationTimer from '../../ReservationTimer';
import { fetchUserReservationThunk, setReservationByPropertySlug } from '../../../store/slices/reservationSlice';
import { fetchPropertyByIdThunk } from '../../../store/slices/propertySlice';
import Loader from '../../../common/Loader';
import formatPropertyId from '../../../utils/PropertyIdConverter';

// Create memoized selectors outside the component
const selectReservationState = createSelector(
  [(state) => state.reservations],
  (reservations) => ({
    currentReservation: reservations.currentReservation,
    loading: reservations.loading
  })
);

const selectPropertyState = createSelector(
  [(state) => state.property],
  (property) => ({
    property: property.property,
    loading: property.loading
  })
);

const ReserveComplete = () => {
  const dispatch = useDispatch();
  const { propertySlug } = useParams();
  const { user: auth0User, loginWithRedirect } = useAuth0();
  
  // Use memoized selectors
  const { currentReservation, loading: reservationLoading } = useSelector(selectReservationState);
  const { property, loading: propertyLoading } = useSelector(selectPropertyState);
  const userId = auth0User?.sub;

  useEffect(() => {

    const fetchData = async () => { 

      if (!userId) {
        const returnUrl = `/${propertySlug}/reserve/complete`;
        
        // Store the dynamic segment (propertySlug) and return path separately
        sessionStorage.setItem('propertySlug', propertySlug);
        sessionStorage.setItem('returnPath', '/reserve/complete');
        
        try {
          await loginWithRedirect({
            appState: { 
              returnTo: returnUrl,
              propertySlug: propertySlug 
            },
            authorizationParams: {
              redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
              response_type: 'code',
              prompt: 'login'
            }
          });
          return;
        } catch (error) {
          console.error('Login redirect error:', error);
        }
      }
      if (userId) {
        const propertyId = formatPropertyId(propertySlug);

        try {
          const token = "homesyToken";
          await dispatch(fetchUserReservationThunk({ userId, token })).unwrap();
          dispatch(setReservationByPropertySlug(propertyId));
          
          if (!property?.property) {
            console.log("Fetching property")
            await dispatch(fetchPropertyByIdThunk(propertyId)).unwrap();
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        console.log('No userId available yet');
      }
    };

    fetchData();
  }, [dispatch, auth0User, propertySlug, userId, loginWithRedirect]);
  
  console.log(currentReservation)
  if (reservationLoading || propertyLoading) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-8">
      {/* Logo container - shown first on mobile/tablet */}
      <div className="lg:hidden w-full flex flex-col items-center text-center">
        <img
          src="/assets/images/traton_logo.png"
          alt="builder logo"
          className="w-[100px] md:w-[140px] lg:w-[175px] h-auto"
        />
        <p className="font-circular text-[13px] md:text-[15px] lg:text-[17px] font-[450] leading-[18px] md:leading-[20px] lg:leading-[20.24px] text-center text-customGray mt-2">
          Powered by <a href="https://yourhomesy.com" target="_blank" rel="noopener noreferrer"><span className="text-[#D66C42]">Homesy.</span></a>
        </p>
      </div>

      <div className="mx-auto flex flex-col lg:flex-row items-center justify-center w-full px-8">
        {/* Left Side - Property Details */}
        <div className="w-full lg:w-1/2 flex flex-col items-center mb-8 lg:mb-0">
          <div className="mt-[20px] flex flex-col items-center justify-center md:w-full lg:items-start max-w-[598px]">
            {property && (
              <>
                <p className="font-circular text-customGray text-base md:text-lg lg:text-xl font-semibold leading-[24px] md:leading-[28px] lg:leading-[34px] text-center lg:text-left mb-4">
                  {"Your Home"}
                </p>
                <div className="w-3/5 lg:w-full">
                  <img
                    src={property.image_urls?.[0] || '/assets/images/placeholder.png'}
                    alt="Property"
                    className="w-full aspect-square rounded-2xl md:rounded-2xl lg:rounded-3xl object-cover"
                  />
                </div>
                <div className="flex flex-col items-center lg:items-start mt-4">
                  <div className="text-center lg:text-left">
                    <p className="font-circular text-customGray text-[16px] md:text-[18px] lg:text-[20px] font-semibold leading-[22px] md:leading-[24px] lg:leading-[26px]">
                      {property.property_line}
                    </p>
                    <p className="font-circular text-customGray text-[14px] md:text-[15px] lg:text-[17px]">
                      {property.property_address}
                    </p>
                    <a 
                      className="font-circular text-[#349cf4] mt-2 inline-block cursor-pointer text-[13px] md:text-[14px] lg:text-[15px]"
                      href={property?.property_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      See details
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Right Side - Reservation Details */}
        <div className="w-full lg:w-1/2 flex flex-col items-center">
          {/* Logo container - shown only on desktop */}
          <div className="hidden lg:flex flex-col items-center text-center mb-8">
            <img
              src="/assets/images/traton_logo.png"
              alt="builder logo"
              className="w-[100px] md:w-[140px] lg:w-[175px] h-auto"
            />
            <p className="font-circular text-[13px] md:text-[15px] lg:text-[17px] font-[450] leading-[18px] md:leading-[20px] lg:leading-[20.24px] text-center text-customGray">
              Powered by <a href="https://yourhomesy.com" target="_blank" rel="noopener noreferrer"><span className="text-[#D66C42]">Homesy.</span></a>
            </p>
          </div>

          {/* Confirmation Content */}
          <div className="flex flex-col items-center w-2/3 px-4 lg:px-8">
            <h1 className="font-circular text-[#0E2132] text-2xl md:text-3xl lg:text-5xl font-[450] leading-[40px] md:leading-[56px] lg:leading-[73px] mb-[20px] text-center">
              Reservation Confirmed!
            </h1>
            
            {currentReservation && property ? (
              <div className="w-full flex flex-col items-center justify-center space-y-4 mb-6">
                  <p className="font-circular text-customGray text-[16px] md:text-[18px] lg:text-[20px] text-center">
                    Congrats on reserving <span className="font-bold">{property.property_line + ", " + property.property_address}</span>, a Traton built home using Homesy. 
                  </p>
                <ReservationTimer 
                  expirationDate={currentReservation.expirationDate}
                />

              </div>
            ) : (
              <div className="text-center text-customGray font-circular text-[16px] md:text-[18px] lg:text-[20px]">
                No active reservations found.
              </div>
            )}
          </div>
          <div className="w-full rounded-full bg-white shadow-md flex justify-between items-center mt-6 ">
                  <a 
                    href={property?.property_url}
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="ont-circular flex-1 px-4 py-2 text-center border rounded-full border-gray text-gray-700 hover:bg-[#E26659] hover:text-white hover:border-[#E26659] transition-colors duration-200"
                  >
                    See House
                  </a>
                  <a 
                    href="/dashboard"
                    className=" font-circular flex-1 px-4 py-2 text-center border rounded-full border-gray text-gray-700 hover:bg-[#E26659] hover:text-white hover:border-[#E26659] transition-colors duration-200"
                  >
                    Go to Dashboard
                  </a>
                  <a 
                    href="/schedule-tour"
                    className="font-circular flex-1 px-4 py-2 text-center border rounded-full border-gray text-gray-700 hover:bg-[#E26659] hover:text-white hover:border-[#E26659] transition-colors duration-200"
                  >
                    Schedule Tour
                  </a>
                </div>
        </div>
      </div>
    </div>
  );
};

export default ReserveComplete;