import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const TopNav = () => {
  const { user, logout } = useAuth0();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  const name = user?.name || user?.nickname || 'Guest';
  const firstLetter = name.charAt(0).toUpperCase();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <nav className="px-6 py-4 bg-white border-b border-[#EBEBEB]">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="text-sm text-gray-500">Powered by <span className="text-[#D66C42]">Homesy.</span></div>
        </div>

        <div>
          <img 
            src="/assets/images/traton_logo.png"
            alt="brand Logo"
            width={64}
            height={52}
            style={{ objectFit: 'contain' }}
          />
        </div>

        <div className="relative" ref={dropdownRef}>
          <button 
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center gap-3"
          >
            <span className="text-gray-700">Hey {name}</span>
            <div className="w-10 h-10 rounded-full overflow-hidden">
              <img
                src={user?.picture} 
                alt={name}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.style.display = 'none';
                  e.target.nextSibling.style.display = 'flex';
                }}
              />
              <div 
                className="w-full h-full bg-[#F5F5F5] hidden items-center justify-center text-gray-600 text-lg font-medium"
                aria-hidden="true"
              >
                {firstLetter}
              </div>
            </div>
          </button>

          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
              <button
                onClick={() => {/* Handle settings */}}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                Settings
              </button>
              {/* <button
                onClick={handleLogout}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                Logout
              </button> */}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default TopNav;
