import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createBuyer = async (buyerData, token) => {
  try {
    console.log('Token being sent:', token);
    
    const response = await axios.post(`${API_BASE_URL}/buyers`, buyerData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (response.status === 201) {
      return response.data;
    }
    throw new Error('Failed to create buyer');
  } catch (error) {
    console.error('Full error object:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      throw new Error(error.response.data.message || 'Failed to create buyer');
    }
    throw error;
  }
}; 