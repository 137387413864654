import { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { clearAction } from './store/slices/flowSlice';
import ReserveFlow from './routes/ReserveFlow';
import GettingStarted from "./pages/GettingStarted";
import ErrorPage from "./pages/404";
import TermAndCondition from "./pages/TermAndCondition";
import LoginPage from "./pages/LoginPage";
import NoPropertyFound from "./pages/NoPropertyFound";
import Dashboard from './components/dashboard';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Loader from './common/Loader';

const App = () => {
  const currentAction = useSelector((state) => state.flow.currentAction);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if (currentAction === 'buy_now') {
      navigate('/buy');
    } else if (currentAction === 'reserve_now') {
      navigate('/reserve');
    }

    return () => {
      dispatch(clearAction());
    };
  }, [currentAction, navigate, dispatch]);

  return (
    <Routes>
      {/* Static Routes */}
      <Route path="/callback" element={<Loader />} />
      <Route path="/no-property-found" element={<NoPropertyFound />} />
      <Route path="/404" element={<ErrorPage />} />
      <Route path="/started" element={<LoginPage />} />
      <Route path="/getting-started" element={<GettingStarted />} />
      <Route path="/t&c" element={<TermAndCondition />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
  
      {/* Dynamic Routes that require propertySlug */}
      <Route path="/:propertySlug/reserve/*" element={<ReserveFlow />} />

      {/* Fallback Routes */}
      <Route path="/reserve" element={<Navigate to="/no-property-found" replace />} />
      <Route path="/buy" element={<Navigate to="/no-property-found" replace />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
  );
};

export default App;
