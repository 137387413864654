import React from 'react';

const NextButton = ({ 
  step, 
  totalSteps, 
  onNext, 
  onSubmit, 
  buttonStyle,
  isLoading 
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (step === totalSteps) {
      onSubmit(e);
    } else {
      onNext();
    }
  };

  const baseButtonClasses = "w-full text-[18px] text-white font-[450] rounded-full flex next-first-btn";

  
  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={isLoading}
      style={buttonStyle}
      className={`${baseButtonClasses} disabled:opacity-70`}
    >
      {isLoading ? (
        <span>Loading...</span>
      ) : step === totalSteps ? (
        'Submit'
      ) : (
        'Next'
      )}
    </button>
  );
};

export default NextButton; 