import React from 'react';
import ProgressBar from '../../../ProgressBar';

const NameStep = ({ userInfo, onChange, errors, onNext, onKeyDown }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
    if (errors[name]) {
      onChange('errors', {
        ...errors,
        [name]: ''
      });
    }
  };

  const handleSubmit = () => {
    if (!userInfo.firstName?.trim() || !userInfo.lastName?.trim()) {
      onChange('errors', {
        ...errors,
        firstName: !userInfo.firstName?.trim() ? 'First name is required' : '',
        lastName: !userInfo.lastName?.trim() ? 'Last name is required' : ''
      });
      return false;
    }
    onNext();
    return true;
  };

  return (
    <>
      <ProgressBar currentStep={2} totalSteps={6} />
      <div className="mb-6">
        <h1 className="font-circular text-[#0E2132] text-2xl md:text-3xl lg:text-5xl font-[450] leading-[40px] md:leading-[56px] lg:leading-[73px] mb-[20px] text-center">
          What's your name?
        </h1>
        <p className="font-circular font-[450] text-base md:text-lg lg:text-xl leading-[24px] md:leading-[28px] lg:leading-[34px] text-center underline-offset-auto decoration-skip-ink-none mb-[40px]">
          Please use your full legal name.
        </p>
      </div>

      <div className="w-full flex flex-col gap-4">
        <div>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={userInfo.firstName || ''}
            onChange={handleChange}
            className="w-full px-4 py-3 text-base md:text-lg text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
            onBlur={handleSubmit}
            onKeyDown={onKeyDown}
          />
          {errors.firstName && (
            <p className="text-red-500 text-sm text-left">
              {errors.firstName}
            </p>
          )}
        </div>

        <div>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={userInfo.lastName || ''}
            onChange={handleChange}
            className="w-full px-4 py-3 text-base md:text-lg text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
            onBlur={handleSubmit}
            onKeyDown={onKeyDown}
          />
          {errors.lastName && (
            <p className="text-red-500 text-sm text-left">
              {errors.lastName}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default NameStep; 