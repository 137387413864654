import React from "react";
import { useNavigate } from "react-router-dom";

const NoPropertyFound = () => {
  const navigate = useNavigate();

  const handleViewProperties = () => {
    window.location.href = "https://www.tratonhomes.com/available-homes/3304-cranston-lane-lot-98-2/"; // Changed navigation to direct link
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold text-red-500 mb-4">No Property Found</h1>
      <p className="text-lg text-gray-700 mb-6">
        The property you're looking for does not exist.
      </p>
      <button
        onClick={handleViewProperties}
        className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
      >
        View Available Properties
      </button>
    </div>
  );
};

export default NoPropertyFound; 