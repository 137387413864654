import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBuilderAgents } from '../../api/builderAgentsApi';

export const fetchBuilderAgentsThunk = createAsyncThunk(
  'builderAgents/fetchBuilderAgents',
  async ({ builderId, token }) => {
    const response = await fetchBuilderAgents(builderId, token);
    return response;
  }
);

const builderAgentsSlice = createSlice({
  name: 'builderAgents',
  initialState: {
    builderAgents: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuilderAgentsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBuilderAgentsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.builderAgents = action.payload;
      })
      .addCase(fetchBuilderAgentsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default builderAgentsSlice.reducer; 