const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUserReservations = async (userId, token) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/reservations/user/${encodeURIComponent(userId)}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    
    if (!response.ok) {
      if (response.status === 404) {
        console.log('No reservations found');
        return { data: [] };
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching user reservations:', error);
    throw error;
  }
};

export const checkReservationStatus = async (reservationId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/reservations/${reservationId}/status`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error checking reservation status:', error);
    throw error;
  }
};

export const createReservation = async (reservationData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/reservations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reservationData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating reservation:', error);
    throw error;
  }
}; 