import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import './fonts.css';
import App from "./App";
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './store';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: "openid profile email offline_access"
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={(appState) => {
        console.log('Auth0 redirect callback triggered', { appState });
        
        // Get the stored propertySlug and return path
        const propertySlug = appState?.propertySlug || sessionStorage.getItem('propertySlug');
        const returnPath = sessionStorage.getItem('returnPath');
        
        if (propertySlug && returnPath) {
          const fullPath = `/${propertySlug}${returnPath}`;
          console.log('Navigating to:', fullPath);
          
          // Clean up storage
          sessionStorage.removeItem('propertySlug');
          sessionStorage.removeItem('returnPath');
          
          window.location.replace(fullPath);
        } else {
          console.log('No return path found, navigating to home');
          window.location.replace('/');
        }
      }}
    >
      <Provider store={store}>
        <Router>
          <App />
        </Router>     
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

