import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';

const ReservationTimer = ({ expirationDate }) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [status, setStatus] = useState('normal'); // 'normal', 'warning', 'danger'

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const expiration = new Date(expirationDate);
      const hoursLeft = (expiration - now) / (1000 * 60 * 60);

      // Set status based on time remaining
      if (hoursLeft <= 6) {
        setStatus('danger');
      } else if (hoursLeft <= 24) {
        setStatus('warning');
      } else {
        setStatus('normal');
      }

      setTimeLeft(formatDistanceToNow(expiration, { addSuffix: true }));
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [expirationDate]);

  const statusColors = {
    normal: 'text-green-600',
    warning: 'text-yellow-600',
    danger: 'text-red-600'
  };

  return (
    <div className="flex items-center space-x-2">
      <div className={`font-semibold ${statusColors[status]}`}>
        Reservation expires {timeLeft}.
      </div>
      {status === 'danger' && (
        <div className="text-red-600 text-sm">
          Reservation expires {timeLeft}. Hurry and schedule a tour!
        </div>
      )}
    </div>
  );
};

export default ReservationTimer; 