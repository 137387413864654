
const Reservation = ({ currentReservation }) => {
  const handleEmailClick = () => {
    const subject = "Schedule Property Visit";
    const body = "I would like to schedule a visit.";
    window.location.href = `mailto:contact@yourhomesy.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  // Format dates
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatTime = (date) => {
    return new Date(date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (!currentReservation) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col gap-8">

      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-semibold text-gray-900">Reservation details</h1>
        </div>
      </div>

      <div className="flex flex-row items-stretch gap-4 h-full w-full">
        
        <div className="flex flex-col gap-2 bg-[#F7F7F7] py-4 px-8 rounded-2xl items-start">
          <h5 className="text-sm font-semibold">Reservation Date</h5>
          <p className="text-md font-light">{formatDate(currentReservation.createdAt)}</p>
          <p className="text-xs">{formatTime(currentReservation.createdAt)}</p>
        </div>

        <div className="flex flex-col gap-2 bg-[#F7F7F7] py-4 px-8 rounded-2xl items-start">
          <h5 className="text-sm font-semibold">Expires On</h5>
          <p className="text-md font-light">{formatDate(currentReservation.expirationDate)}</p>
          <p className="text-xs">{formatTime(currentReservation.expirationDate)}</p>
        </div>

        <div className="flex flex-col gap-2 bg-[#F7F7F7] p-4 rounded-2xl items-start flex-1">
          <h5 className="text-sm font-semibold">Payment Method</h5>
          <div className="flex flex-row gap-2 justify-between items-center w-full">
            <div className="flex flex-row gap-2 items-center">
              <p className="text-sm font-medium">Card</p>
              <p className="text-xs text-gray-500">•••• 4242</p>
            </div>
            <div className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="1" y="4" width="22" height="16" rx="2" ry="2"/>
                <line x1="1" y1="10" x2="23" y2="10"/>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-2 items-center">
        <p className="text-sm font-medium">Ready to schedule your visit?</p>
        <button 
          onClick={handleEmailClick}
          className="bg-[#E26659] text-white px-4 py-2 rounded-full flex items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
            <polyline points="22,6 12,13 2,6" />
          </svg>
          Email to schedule
        </button>
      </div>
    </div>
  );
};

export default Reservation;
