import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBuilderById } from '../../api/builderApi';

export const fetchBuilderByIdThunk = createAsyncThunk(
  'builder/fetchBuilderById',
  async (builderId, thunkAPI) => {
    try {
      const builderData = await fetchBuilderById(builderId);
      return builderData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const builderSlice = createSlice({
  name: 'builder',
  initialState: {
    builder: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearBuilder: (state) => {
      state.builder = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuilderByIdThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBuilderByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.builder = action.payload;
      })
      .addCase(fetchBuilderByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearBuilder } = builderSlice.actions;
export default builderSlice.reducer; 