import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPropertyById } from '../../api/propertyApi';

// Async thunk to fetch property data
export const fetchPropertyByIdThunk = createAsyncThunk(
    'property/fetchPropertyById',
    async (propertyId, thunkAPI) => {
        try {
            const data = await fetchPropertyById(propertyId);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const propertySlice = createSlice({
    name: 'property',
    initialState: {
        property: null,
        loading: false,
        error: null,
        isReserved: false,
    },
    reducers: {
        setProperty: (state, action) => {
            state.property = action.payload;
        },
        clearProperty: (state) => {
            state.property = null;
        },
        setAction(state, action) {
            console.log('Setting action:', action.payload);
            state.action = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPropertyByIdThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.property = null;
            })
            .addCase(fetchPropertyByIdThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.property = action.payload;
                state.isReserved = action.payload?.propertystatus === 'reserved';
            })
            .addCase(fetchPropertyByIdThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch property data';
            });
    },
});

export const { setProperty, clearProperty, setAction } = propertySlice.actions;

export default propertySlice.reducer; 