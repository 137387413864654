const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch builder data by builder ID
export const fetchBuilderById = async (builderId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/builders/${builderId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch builder data');
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error in fetchBuilderById:', error);
        throw error;
    }
};