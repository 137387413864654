import { useState } from 'react';

export const useFormValidation = (termsAccepted) => {
  const [errors, setErrors] = useState({});

  const validate = (dataObject, step) => {
    const errors = {};
    if (!dataObject.firstName && step === 2) {
      errors.firstName = "First name is required";
    }
    if (!dataObject.lastName && step === 2) {
      errors.lastName = "Last name is required";
    }
    if (!dataObject.email && step === 3) {
      errors.email = "Email is required";
    } else if (step === 3 && !/\S+@\S+\.\S+/.test(dataObject.email)) {
      errors.email = "Email address is invalid";
    }
    if (!dataObject.phone && step === 4) {
      errors.phone = "Phone number is required";
    }
    if (!dataObject.address && step === 5) {
      errors.address = "Address is required";
    }
    if (step === 5 && !termsAccepted) {
      errors.terms = "You must agree to the terms and conditions";
    }
    return errors;
  };

  return { errors, setErrors, validate };
};

export default useFormValidation; 