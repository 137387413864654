const HouseInfo = ({ selectedProperty, currentReservation }) => {
  const propertyData = selectedProperty || {
    property_full_address: 'property_full_address',
    community: 'community',
    propertyLink: 'propertyLink',
    communityLink: 'communityLink'
  };

  const handleSeeDetails = () => {
    window.open(propertyData.property_url, '_blank');
  };

  const handleCommunityClick = () => {
    window.open(propertyData.community_url, '_blank');
  };

  return (
    <div className="relative px-6 py-4">
      {/* Image container with status badge */}
      <div className="relative overflow-hidden rounded-xl">
        <img 
          src={propertyData?.image_urls ? propertyData.image_urls[0] : '/images/default-property-image.jpg'} 
          alt={propertyData?.property_full_address || 'Property Image'}
          className="w-full h-full object-cover"
        />
        
        {currentReservation && (
          <div className="absolute top-4 right-4 bg-green-500 text-white px-3 py-1 rounded-full text-sm">
            {currentReservation.status}
          </div>
        )}
      </div>

      {/* Property information */}
      <div className="mt-4 flex justify-between flex-col">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col">
            <span className="text-gray-500 text-xs">Property Address</span>
            <span className="text-gray-900 font-light mt-1">
              {propertyData.property_full_address}
            </span>
            <span>
              <button 
                onClick={handleSeeDetails}
                className="text-[#4C91FF] text-xs cursor-pointer hover:underline"
              >
                see details
              </button>
            </span>
          </div>

          <div className="flex flex-col items-start">
            <span className="text-gray-500 text-xs">Community</span>
            <div className="flex items-center gap-2">
              <button 
                onClick={handleCommunityClick}
                className="text-gray-900 font-light mt-1 text-[#4C91FF] hover:underline"
              >
                {propertyData.community}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseInfo;
