import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from 'react-redux';
import { setProperty } from '../store/slices/propertySlice';

function LoginPage() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const urlParams = new URLSearchParams(window.location.search);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const data = urlParams.get("data");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedProperty = useSelector((state) => state.property.selectedProperty);
  const currentAction = useSelector((state) => state.flow.currentAction);

  const getBuilderInfo = async (id) => {
    try {
      const response = await fetch(`${apiBaseUrl}/builders/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('builderResponse', response);
      if (response.ok) {
        const data = await response.json();
        console.log('builderResponse', data.data.builder);
        localStorage.setItem("builderInfo", JSON.stringify(data.data.builder));
        // setBuilderInfo(data.data.builder);        
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const arrayToObject = (arr) =>  {
    return arr.reduce((acc, curr) => {
      acc[curr.elementName] = curr.elementValue;
      return acc;
    }, {});
  }

  const getBuilderConfig = async (id) => {
    try {
      const response = await fetch(`${apiBaseUrl}/builderconfig/builder/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('builderConfigResponse', response);
      if (response.ok) {       
        const data = await response.json();
        const transformedData = arrayToObject(data.data);
        console.log('builderConfigResponse', transformedData);
        localStorage.setItem("builderConfig", JSON.stringify(transformedData));
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const secretKey = process.env.REACT_APP_SECRET_KEY; // Same secret key used for encryption

  useEffect(() => {
    const processDecryptedData = async () => {
      if (!data || data.length === 0) return;
      try {
        // Decrypt the data
        const bytes = CryptoJS.AES.decrypt(decodeURIComponent(data), secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (decryptedData) {
             // Only fetch builder info if builderID exists
          if (decryptedData?.builderID) {
            await getBuilderInfo(decryptedData.builderID);
            await getBuilderConfig(decryptedData.builderID);
            // setIsLoading(false);
            if (isAuthenticated) {
              navigate("/user-info");
            } else {
              loginWithRedirect();
            }
          }

          // Save data to localStorage
          localStorage.setItem("propertyInfo", JSON.stringify(decryptedData));
          
          // Set selected property if not already set
          if (!selectedProperty) {
            dispatch(setProperty(decryptedData));
          }
        }
      } catch (error) {
        console.error('Error decrypting or processing data:', error);
      }
    };

    processDecryptedData();
  }, [data, secretKey, isAuthenticated, navigate, selectedProperty, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // ... existing logic

    // Handle navigation based on Redux state
    if (currentAction === "buy_now") {
      navigate("/buy");
    } else if (currentAction === "reserve_now") {
      navigate("/reserve");
    }
  };

  return (
    <div><Loader/></div>
  )
}

export default LoginPage