import { useState } from 'react';

export const usePhoneInput = (initialValue = '', onChange) => {
  const [phoneNumber, setPhoneNumber] = useState(initialValue);

  const formatPhoneNumber = (value) => {
    // Remove all non-digits
    const digits = value.replace(/\D/g, '');
    
    // Return empty if no digits
    if (!digits.length) return '';
    
    // Format based on number of digits entered
    if (digits.length <= 3) {
      return `(${digits}`;
    }
    if (digits.length <= 6) {
      return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    }
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const digits = input.replace(/\D/g, '').slice(0, 10);
    const formattedNumber = formatPhoneNumber(digits);
    
    setPhoneNumber(formattedNumber);
    onChange(formattedNumber);
  };

  return [phoneNumber, handlePhoneChange];
};

export default usePhoneInput; 