import React from 'react';
import ProgressBar from '../../../ProgressBar';
import { Autocomplete } from '@react-google-maps/api';
import useAddressInput from '../hooks/useAddressInput';

const AddressStep = React.memo(({ userInfo, onChange, handleTermsChange, termsAccepted, errors, onNext, onKeyDown }) => {
  const {
    location,
    setLocation,
    autocompleteRef,
    isLoaded,
    loadError,
    handlePlaceChanged,
  } = useAddressInput((addressData) => {
    Object.entries(addressData).forEach(([key, value]) => {
      onChange(key, value);
    });
  });

  const handleSubmit = () => {
    const errors = {};
    
    if (!userInfo.address?.trim()) {
      errors.address = 'Please enter your address';
    }
    
    if (!termsAccepted) {
      errors.terms = 'You must accept the Terms and Conditions to continue';
    }

    if (Object.keys(errors).length > 0) {
      onChange('errors', {
        ...errors,
        ...errors
      });
      return false;
    }
    onNext();
    return true;
  };

  const renderAddressInput = () => {
    if (loadError) {
      return (
        <input
          type="text"
          placeholder="Enter your full address"
          name="address"
          value={userInfo.address || ''}
          onChange={(e) => onChange('address', e.target.value)}
          onKeyDown={onKeyDown}
          className="w-full px-4 py-3 text-base md:text-lg text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
        />
      );
    }

    if (!isLoaded) {
      return <div>Loading...</div>;
    }

    return (
      <Autocomplete
        onLoad={autocomplete => {
          autocompleteRef.current = autocomplete;
        }}
        onPlaceChanged={handlePlaceChanged}
        onBlur={handleSubmit}
        onKeyDown={onKeyDown}
      >
        <input
          type="text"
          placeholder="Enter your full address"
          value={location || ''}
          onChange={(e) => setLocation(e.target.value)}
          onKeyDown={onKeyDown}
          className="w-full px-4 py-3 text-base md:text-lg text-[#3B4547] border bg-[#F8F7F5] border-[#E3E3E3] rounded-lg focus:outline-none focus:border-[#E3E3E3]"
        />
      </Autocomplete>
    );
  };

  return (
    <>
      <ProgressBar currentStep={5} totalSteps={6} />
      <div className="mb-6">
        <h1 className="font-circular text-[#0E2132] text-2xl md:text-3xl lg:text-5xl font-[450] leading-[40px] md:leading-[56px] lg:leading-[73px] mb-[20px] text-center">
          Your current address.
        </h1>
        <p className="font-circular font-[450] text-base md:text-lg lg:text-xl leading-[24px] md:leading-[28px] lg:leading-[34px] text-center underline-offset-auto decoration-skip-ink-none mb-[40px]">
          Can be a home, rental or even your parent's place.
        </p>
      </div>

      <div className="w-full mb-[18px]">
        {renderAddressInput()}
        {errors.address && (
          <p className="text-red-500 text-sm text-left mt-2">
            {errors.address}
          </p>
        )}
      </div>

      <div className="flex flex-col items-start w-full mt-[31px]">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="terms"
            className="mr-2"
            checked={termsAccepted}
            onChange={handleTermsChange}
          />
          <label
            htmlFor="terms"
            className="text-customGray font-circular leading-[20.24px] text-[16px]"
          >
            I agree to the{" "}
            <a
              href="/T&C"
              className="text-[#6C1513] underline font-circular leading-[20.24px] text-[16px]"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </label>
        </div>
        {errors.terms && (
          <p className="text-red-500 text-sm mt-2">
            {errors.terms}
          </p>
        )}
      </div>
    </>
  );
});

AddressStep.displayName = 'AddressStep';

export default AddressStep; 